@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-ExtraBold.woff2') format('woff2'),
    url('../assets/font/InterUI-ExtraBold.woff') format('woff'),
    url('../assets/font/InterUI-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-BoldItalic.woff2') format('woff2'),
    url('../assets/font/InterUI-BoldItalic.woff') format('woff'),
    url('../assets/font/InterUI-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-BlackItalic.woff2') format('woff2'),
    url('../assets/font/InterUI-BlackItalic.woff') format('woff'),
    url('../assets/font/InterUI-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Bold.woff2') format('woff2'),
    url('../assets/font/InterUI-Bold.woff') format('woff'),
    url('../assets/font/InterUI-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-SemiBoldItalic.woff2') format('woff2'),
    url('../assets/font/InterUI-SemiBoldItalic.woff') format('woff'),
    url('../assets/font/InterUI-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-SemiBold.woff2') format('woff2'),
    url('../assets/font/InterUI-SemiBold.woff') format('woff'),
    url('../assets/font/InterUI-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Italic.woff2') format('woff2'),
    url('../assets/font/InterUI-Italic.woff') format('woff'),
    url('../assets/font/InterUI-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Black.woff2') format('woff2'),
    url('../assets/font/InterUI-Black.woff') format('woff'),
    url('../assets/font/InterUI-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Medium.woff2') format('woff2'),
    url('../assets/font/InterUI-Medium.woff') format('woff'),
    url('../assets/font/InterUI-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-MediumItalic.woff2') format('woff2'),
    url('../assets/font/InterUI-MediumItalic.woff') format('woff'),
    url('../assets/font/InterUI-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Regular.woff2') format('woff2'),
    url('../assets/font/InterUI-Regular.woff') format('woff'),
    url('../assets/font/InterUI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-ExtraBoldItalic.woff2') format('woff2'),
    url('../assets/font/InterUI-ExtraBoldItalic.woff') format('woff'),
    url('../assets/font/InterUI-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Regular_1.woff2') format('woff2'),
    url('../assets/font/InterUI-Regular_1.woff') format('woff'),
    url('../assets/font/InterUI-Regular_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Medium_1.woff2') format('woff2'),
    url('../assets/font/InterUI-Medium_1.woff') format('woff'),
    url('../assets/font/InterUI-Medium_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-SemiBold_1.woff2') format('woff2'),
    url('../assets/font/InterUI-SemiBold_1.woff') format('woff'),
    url('../assets/font/InterUI-SemiBold_1.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-ExtraBoldItalic_1.woff2') format('woff2'),
    url('../assets/font/InterUI-ExtraBoldItalic_1.woff') format('woff'),
    url('../assets/font/InterUI-ExtraBoldItalic_1.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-BlackItalic_1.woff2') format('woff2'),
    url('../assets/font/InterUI-BlackItalic_1.woff') format('woff'),
    url('../assets/font/InterUI-BlackItalic_1.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-SemiBoldItalic_1.woff2') format('woff2'),
    url('../assets/font/InterUI-SemiBoldItalic_1.woff') format('woff'),
    url('../assets/font/InterUI-SemiBoldItalic_1.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-ExtraBold_1.woff2') format('woff2'),
    url('../assets/font/InterUI-ExtraBold_1.woff') format('woff'),
    url('../assets/font/InterUI-ExtraBold_1.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-BoldItalic_1.woff2') format('woff2'),
    url('../assets/font/InterUI-BoldItalic_1.woff') format('woff'),
    url('../assets/font/InterUI-BoldItalic_1.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Italic_1.woff2') format('woff2'),
    url('../assets/font/InterUI-Italic_1.woff') format('woff'),
    url('../assets/font/InterUI-Italic_1.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Black_1.woff2') format('woff2'),
    url('../assets/font/InterUI-Black_1.woff') format('woff'),
    url('../assets/font/InterUI-Black_1.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-MediumItalic_1.woff2') format('woff2'),
    url('../assets/font/InterUI-MediumItalic_1.woff') format('woff'),
    url('../assets/font/InterUI-MediumItalic_1.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../assets/font/InterUI-Bold_1.woff2') format('woff2'),
    url('../assets/font/InterUI-Bold_1.woff') format('woff'),
    url('../assets/font/InterUI-Bold_1.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
