$text-body: #222222;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: -1.8px;
  color: $text-body;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: -1.52px;
  color: $text-body;
}

h3 {
  font-weight: 500;
  font-size: 19px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-body;
  letter-spacing: -0.65px;
  &.bold {
    font-weight: bold;
    letter-spacing: 0;
  }
}

h4 {
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: $text-body;
  &.bold {
    letter-spacing: -0.23px;
    font-weight: bold;
  }
}

body,
p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.18px;
  color: $text-body;
}

label {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-body;
}
