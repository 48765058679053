.Toastify__toast {
  border-radius: 0.25rem;
  color: #222222;
  padding-left: 1.5rem;
  min-height: 40px;
  &.Toastify__toast--error {
    background-color: white;
    color: #222222;
    padding-left: 1rem;
    border-left: 0.5rem solid #cc0500;
  }
  .Toastify__close-button {
    color: #97979e;
    opacity: 1;
    margin: auto 0;
    &:hover {
      color: #222222;
    }
  }
}
