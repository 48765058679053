@-moz-keyframes bubbles {
  to {
    background-position: 1180px 0;
  }
}
@-webkit-keyframes bubbles {
  to {
    background-position: 1180px 0;
  }
}
@keyframes bubbles {
  to {
    background-position: 1180px 0;
  }
}
#beer2 {
  height: 50vh;
  background-color: #fff;
  font-weight: 900;
  position: relative;
}
#beer2 > div {
  width: 52px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-repeat: repeat-x;
  -webkit-animation: bubbles 8s linear infinite;
  -moz-animation: bubbles 8s linear infinite;
  animation: bubbles 8s linear infinite;
  -ms-animation: bubbles 8s linear infinite;
}
#beer2 > div > div {
  position: relative;
  z-index: 400000;
  width: 100%;
  padding-bottom: 100%;
  height: 86px;
  width: 53px;
  background-repeat: no-repeat;
}
#beer2 > div .bubble {
  border-radius: 100%;
  display: block;
  z-index: 2;
}
#beer2 > div .bubble:nth-child(40) {
  background-color: rgba(255, 255, 255, 0.2097126);
  width: 5.51353179px;
  height: 5.51353179px;
  position: absolute;
  left: 22.69055061%;
  bottom: 0;
  -webkit-animation: bubble 1.79584248s infinite linear;
  -moz-animation: bubble 1.79584248s infinite linear;
  animation: bubble 1.79584248s infinite linear;
  -ms-animation: bubble 1.79584248s infinite linear;
}
#beer2 > div .bubble:nth-child(39) {
  background-color: rgba(255, 255, 255, 0.22955048);
  width: 3.90494594px;
  height: 3.90494594px;
  position: absolute;
  left: 29.72859072%;
  bottom: 0;
  -webkit-animation: bubble 1.7759423s infinite linear;
  -moz-animation: bubble 1.7759423s infinite linear;
  animation: bubble 1.7759423s infinite linear;
  -ms-animation: bubble 1.7759423s infinite linear;
}
#beer2 > div .bubble:nth-child(38) {
  background-color: rgba(255, 255, 255, 0.64462769);
  width: 2.92031831px;
  height: 2.92031831px;
  position: absolute;
  left: 36.79803448%;
  bottom: 0;
  -webkit-animation: bubble 1.12690565s infinite linear;
  -moz-animation: bubble 1.12690565s infinite linear;
  animation: bubble 1.12690565s infinite linear;
  -ms-animation: bubble 1.12690565s infinite linear;
}
#beer2 > div .bubble:nth-child(37) {
  background-color: rgba(255, 255, 255, 0.6923575);
  width: 3.72638983px;
  height: 3.72638983px;
  position: absolute;
  left: 36.60826451%;
  bottom: 0;
  -webkit-animation: bubble 1.56477142s infinite linear;
  -moz-animation: bubble 1.56477142s infinite linear;
  animation: bubble 1.56477142s infinite linear;
  -ms-animation: bubble 1.56477142s infinite linear;
}
#beer2 > div .bubble:nth-child(36) {
  background-color: rgba(255, 255, 255, 0.29685544);
  width: 3.85580414px;
  height: 3.85580414px;
  position: absolute;
  left: 46.30128834%;
  bottom: 0;
  -webkit-animation: bubble 1.32111858s infinite linear;
  -moz-animation: bubble 1.32111858s infinite linear;
  animation: bubble 1.32111858s infinite linear;
  -ms-animation: bubble 1.32111858s infinite linear;
}
#beer2 > div .bubble:nth-child(35) {
  background-color: rgba(255, 255, 255, 0.29397705);
  width: 5.77974442px;
  height: 5.77974442px;
  position: absolute;
  left: 51.56148752%;
  bottom: 0;
  -webkit-animation: bubble 1.00030545s infinite linear;
  -moz-animation: bubble 1.00030545s infinite linear;
  animation: bubble 1.00030545s infinite linear;
  -ms-animation: bubble 1.00030545s infinite linear;
}
#beer2 > div .bubble:nth-child(34) {
  background-color: rgba(255, 255, 255, 0.79868439);
  width: 3.09938284px;
  height: 3.09938284px;
  position: absolute;
  left: 27.30600702%;
  bottom: 0;
  -webkit-animation: bubble 1.00016769s infinite linear;
  -moz-animation: bubble 1.00016769s infinite linear;
  animation: bubble 1.00016769s infinite linear;
  -ms-animation: bubble 1.00016769s infinite linear;
}
#beer2 > div .bubble:nth-child(33) {
  background-color: rgba(255, 255, 255, 0.65402494);
  width: 2.31033085px;
  height: 2.31033085px;
  position: absolute;
  left: 36.21669967%;
  bottom: 0;
  -webkit-animation: bubble 1.21183007s infinite linear;
  -moz-animation: bubble 1.21183007s infinite linear;
  animation: bubble 1.21183007s infinite linear;
  -ms-animation: bubble 1.21183007s infinite linear;
}
#beer2 > div .bubble:nth-child(32) {
  background-color: rgba(255, 255, 255, 0.27003067);
  width: 4.90858219px;
  height: 4.90858219px;
  position: absolute;
  left: 35.71705545%;
  bottom: 0;
  -webkit-animation: bubble 1.11484573s infinite linear;
  -moz-animation: bubble 1.11484573s infinite linear;
  animation: bubble 1.11484573s infinite linear;
  -ms-animation: bubble 1.11484573s infinite linear;
}
#beer2 > div .bubble:nth-child(31) {
  background-color: rgba(255, 255, 255, 0.1622001);
  width: 5.41351044px;
  height: 5.41351044px;
  position: absolute;
  left: 41.57412168%;
  bottom: 0;
  -webkit-animation: bubble 1.60336623s infinite linear;
  -moz-animation: bubble 1.60336623s infinite linear;
  animation: bubble 1.60336623s infinite linear;
  -ms-animation: bubble 1.60336623s infinite linear;
}
#beer2 > div .bubble:nth-child(30) {
  background-color: rgba(255, 255, 255, 0.30419281);
  width: 5.91273882px;
  height: 5.91273882px;
  position: absolute;
  left: 51.9910582%;
  bottom: 0;
  -webkit-animation: bubble 1.77247856s infinite linear;
  -moz-animation: bubble 1.77247856s infinite linear;
  animation: bubble 1.77247856s infinite linear;
  -ms-animation: bubble 1.77247856s infinite linear;
}
#beer2 > div .bubble:nth-child(29) {
  background-color: rgba(255, 255, 255, 0.38583389);
  width: 5.60057164px;
  height: 5.60057164px;
  position: absolute;
  left: 53.84938308%;
  bottom: 0;
  -webkit-animation: bubble 1.52374886s infinite linear;
  -moz-animation: bubble 1.52374886s infinite linear;
  animation: bubble 1.52374886s infinite linear;
  -ms-animation: bubble 1.52374886s infinite linear;
}
#beer2 > div .bubble:nth-child(28) {
  background-color: rgba(255, 255, 255, 0.47945552);
  width: 4.14648817px;
  height: 4.14648817px;
  position: absolute;
  left: 40.9724846%;
  bottom: 0;
  -webkit-animation: bubble 1.37641437s infinite linear;
  -moz-animation: bubble 1.37641437s infinite linear;
  animation: bubble 1.37641437s infinite linear;
  -ms-animation: bubble 1.37641437s infinite linear;
}
#beer2 > div .bubble:nth-child(27) {
  background-color: rgba(255, 255, 255, 0.10367305);
  width: 2.98532063px;
  height: 2.98532063px;
  position: absolute;
  left: 28.04452227%;
  bottom: 0;
  -webkit-animation: bubble 1.06002194s infinite linear;
  -moz-animation: bubble 1.06002194s infinite linear;
  animation: bubble 1.06002194s infinite linear;
  -ms-animation: bubble 1.06002194s infinite linear;
}
#beer2 > div .bubble:nth-child(26) {
  background-color: rgba(255, 255, 255, 0.43601128);
  width: 3.33121583px;
  height: 3.33121583px;
  position: absolute;
  left: 37.45783828%;
  bottom: 0;
  -webkit-animation: bubble 1.08974736s infinite linear;
  -moz-animation: bubble 1.08974736s infinite linear;
  animation: bubble 1.08974736s infinite linear;
  -ms-animation: bubble 1.08974736s infinite linear;
}
#beer2 > div .bubble:nth-child(25) {
  background-color: rgba(255, 255, 255, 0.18094571);
  width: 2.18922577px;
  height: 2.18922577px;
  position: absolute;
  left: 30.47793305%;
  bottom: 0;
  -webkit-animation: bubble 1.25683567s infinite linear;
  -moz-animation: bubble 1.25683567s infinite linear;
  animation: bubble 1.25683567s infinite linear;
  -ms-animation: bubble 1.25683567s infinite linear;
}
#beer2 > div .bubble:nth-child(24) {
  background-color: rgba(255, 255, 255, 0.7254762);
  width: 4.04921232px;
  height: 4.04921232px;
  position: absolute;
  left: 28.16836109%;
  bottom: 0;
  -webkit-animation: bubble 1.75709714s infinite linear;
  -moz-animation: bubble 1.75709714s infinite linear;
  animation: bubble 1.75709714s infinite linear;
  -ms-animation: bubble 1.75709714s infinite linear;
}
#beer2 > div .bubble:nth-child(23) {
  background-color: rgba(255, 255, 255, 0.22258917);
  width: 3.20516912px;
  height: 3.20516912px;
  position: absolute;
  left: 39.60288382%;
  bottom: 0;
  -webkit-animation: bubble 1.22964229s infinite linear;
  -moz-animation: bubble 1.22964229s infinite linear;
  animation: bubble 1.22964229s infinite linear;
  -ms-animation: bubble 1.22964229s infinite linear;
}
#beer2 > div .bubble:nth-child(22) {
  background-color: rgba(255, 255, 255, 0.19956239);
  width: 4.60971257px;
  height: 4.60971257px;
  position: absolute;
  left: 27.45089698%;
  bottom: 0;
  -webkit-animation: bubble 1.29192554s infinite linear;
  -moz-animation: bubble 1.29192554s infinite linear;
  animation: bubble 1.29192554s infinite linear;
  -ms-animation: bubble 1.29192554s infinite linear;
}
#beer2 > div .bubble:nth-child(21) {
  background-color: rgba(255, 255, 255, 0.43904675);
  width: 4.71141857px;
  height: 4.71141857px;
  position: absolute;
  left: 26.20259656%;
  bottom: 0;
  -webkit-animation: bubble 1.78683573s infinite linear;
  -moz-animation: bubble 1.78683573s infinite linear;
  animation: bubble 1.78683573s infinite linear;
  -ms-animation: bubble 1.78683573s infinite linear;
}
#beer2 > div .bubble:nth-child(20) {
  background-color: rgba(255, 255, 255, 0.38190045);
  width: 5.13252528px;
  height: 5.13252528px;
  position: absolute;
  left: 58.8102553%;
  bottom: 0;
  -webkit-animation: bubble 1.56551275s infinite linear;
  -moz-animation: bubble 1.56551275s infinite linear;
  animation: bubble 1.56551275s infinite linear;
  -ms-animation: bubble 1.56551275s infinite linear;
}
#beer2 > div .bubble:nth-child(19) {
  background-color: rgba(255, 255, 255, 0.15673573);
  width: 3.65668599px;
  height: 3.65668599px;
  position: absolute;
  left: 49.6078016%;
  bottom: 0;
  -webkit-animation: bubble 1.25781212s infinite linear;
  -moz-animation: bubble 1.25781212s infinite linear;
  animation: bubble 1.25781212s infinite linear;
  -ms-animation: bubble 1.25781212s infinite linear;
}
#beer2 > div .bubble:nth-child(18) {
  background-color: rgba(255, 255, 255, 0.58890871);
  width: 2.94092463px;
  height: 2.94092463px;
  position: absolute;
  left: 38.3432032%;
  bottom: 0;
  -webkit-animation: bubble 1.22678068s infinite linear;
  -moz-animation: bubble 1.22678068s infinite linear;
  animation: bubble 1.22678068s infinite linear;
  -ms-animation: bubble 1.22678068s infinite linear;
}
#beer2 > div .bubble:nth-child(17) {
  background-color: rgba(255, 255, 255, 0.86815967);
  width: 5.2679824px;
  height: 5.2679824px;
  position: absolute;
  left: 58.25823499%;
  bottom: 0;
  -webkit-animation: bubble 1.60618312s infinite linear;
  -moz-animation: bubble 1.60618312s infinite linear;
  animation: bubble 1.60618312s infinite linear;
  -ms-animation: bubble 1.60618312s infinite linear;
}
#beer2 > div .bubble:nth-child(16) {
  background-color: rgba(255, 255, 255, 0.47305876);
  width: 5.29496281px;
  height: 5.29496281px;
  position: absolute;
  left: 41.31471597%;
  bottom: 0;
  -webkit-animation: bubble 1.06679629s infinite linear;
  -moz-animation: bubble 1.06679629s infinite linear;
  animation: bubble 1.06679629s infinite linear;
  -ms-animation: bubble 1.06679629s infinite linear;
}
#beer2 > div .bubble:nth-child(15) {
  background-color: rgba(255, 255, 255, 0.46911021);
  width: 4.75816993px;
  height: 4.75816993px;
  position: absolute;
  left: 57.13376617%;
  bottom: 0;
  -webkit-animation: bubble 1.58718385s infinite linear;
  -moz-animation: bubble 1.58718385s infinite linear;
  animation: bubble 1.58718385s infinite linear;
  -ms-animation: bubble 1.58718385s infinite linear;
}
#beer2 > div .bubble:nth-child(14) {
  background-color: rgba(255, 255, 255, 0.62667131);
  width: 3.44320527px;
  height: 3.44320527px;
  position: absolute;
  left: 29.13772389%;
  bottom: 0;
  -webkit-animation: bubble 1.01193006s infinite linear;
  -moz-animation: bubble 1.01193006s infinite linear;
  animation: bubble 1.01193006s infinite linear;
  -ms-animation: bubble 1.01193006s infinite linear;
}
#beer2 > div .bubble:nth-child(13) {
  background-color: rgba(255, 255, 255, 0.24202325);
  width: 4.10534257px;
  height: 4.10534257px;
  position: absolute;
  left: 27.18206056%;
  bottom: 0;
  -webkit-animation: bubble 1.70418087s infinite linear;
  -moz-animation: bubble 1.70418087s infinite linear;
  animation: bubble 1.70418087s infinite linear;
  -ms-animation: bubble 1.70418087s infinite linear;
}
#beer2 > div .bubble:nth-child(12) {
  background-color: rgba(255, 255, 255, 0.19885992);
  width: 2.42326428px;
  height: 2.42326428px;
  position: absolute;
  left: 35.81782982%;
  bottom: 0;
  -webkit-animation: bubble 1.52374641s infinite linear;
  -moz-animation: bubble 1.52374641s infinite linear;
  animation: bubble 1.52374641s infinite linear;
  -ms-animation: bubble 1.52374641s infinite linear;
}
#beer2 > div .bubble:nth-child(11) {
  background-color: rgba(255, 255, 255, 0.32364938);
  width: 3.929564px;
  height: 3.929564px;
  position: absolute;
  left: 19.99127468%;
  bottom: 0;
  -webkit-animation: bubble 1.40474816s infinite linear;
  -moz-animation: bubble 1.40474816s infinite linear;
  animation: bubble 1.40474816s infinite linear;
  -ms-animation: bubble 1.40474816s infinite linear;
}
#beer2 > div .bubble:nth-child(10) {
  background-color: rgba(255, 255, 255, 0.46282288);
  width: 3.81601953px;
  height: 3.81601953px;
  position: absolute;
  left: 23.13203697%;
  bottom: 0;
  -webkit-animation: bubble 1.48397397s infinite linear;
  -moz-animation: bubble 1.48397397s infinite linear;
  animation: bubble 1.48397397s infinite linear;
  -ms-animation: bubble 1.48397397s infinite linear;
}
#beer2 > div .bubble:nth-child(9) {
  background-color: rgba(255, 255, 255, 0.83824511);
  width: 5.92029084px;
  height: 5.92029084px;
  position: absolute;
  left: 35.56033319%;
  bottom: 0;
  -webkit-animation: bubble 1.65349311s infinite linear;
  -moz-animation: bubble 1.65349311s infinite linear;
  animation: bubble 1.65349311s infinite linear;
  -ms-animation: bubble 1.65349311s infinite linear;
}
#beer2 > div .bubble:nth-child(8) {
  background-color: rgba(255, 255, 255, 0.19467195);
  width: 3.07283453px;
  height: 3.07283453px;
  position: absolute;
  left: 45.24411912%;
  bottom: 0;
  -webkit-animation: bubble 1.61693049s infinite linear;
  -moz-animation: bubble 1.61693049s infinite linear;
  animation: bubble 1.61693049s infinite linear;
  -ms-animation: bubble 1.61693049s infinite linear;
}
#beer2 > div .bubble:nth-child(7) {
  background-color: rgba(255, 255, 255, 0.14658402);
  width: 5.64393912px;
  height: 5.64393912px;
  position: absolute;
  left: 19.31267895%;
  bottom: 0;
  -webkit-animation: bubble 1.22957028s infinite linear;
  -moz-animation: bubble 1.22957028s infinite linear;
  animation: bubble 1.22957028s infinite linear;
  -ms-animation: bubble 1.22957028s infinite linear;
}
#beer2 > div .bubble:nth-child(6) {
  background-color: rgba(255, 255, 255, 0.10775464);
  width: 2.48747646px;
  height: 2.48747646px;
  position: absolute;
  left: 18.10159888%;
  bottom: 0;
  -webkit-animation: bubble 1.53901592s infinite linear;
  -moz-animation: bubble 1.53901592s infinite linear;
  animation: bubble 1.53901592s infinite linear;
  -ms-animation: bubble 1.53901592s infinite linear;
}
#beer2 > div .bubble:nth-child(5) {
  background-color: rgba(255, 255, 255, 0.83435612);
  width: 3.93900206px;
  height: 3.93900206px;
  position: absolute;
  left: 44.35785697%;
  bottom: 0;
  -webkit-animation: bubble 1.30740682s infinite linear;
  -moz-animation: bubble 1.30740682s infinite linear;
  animation: bubble 1.30740682s infinite linear;
  -ms-animation: bubble 1.30740682s infinite linear;
}
#beer2 > div .bubble:nth-child(4) {
  background-color: rgba(255, 255, 255, 0.84157773);
  width: 2.60352272px;
  height: 2.60352272px;
  position: absolute;
  left: 54.94254545%;
  bottom: 0;
  -webkit-animation: bubble 1.4408637s infinite linear;
  -moz-animation: bubble 1.4408637s infinite linear;
  animation: bubble 1.4408637s infinite linear;
  -ms-animation: bubble 1.4408637s infinite linear;
}
#beer2 > div .bubble:nth-child(3) {
  background-color: rgba(255, 255, 255, 0.79383738);
  width: 3.25970447px;
  height: 3.25970447px;
  position: absolute;
  left: 56.17003312%;
  bottom: 0;
  -webkit-animation: bubble 1.34113844s infinite linear;
  -moz-animation: bubble 1.34113844s infinite linear;
  animation: bubble 1.34113844s infinite linear;
  -ms-animation: bubble 1.34113844s infinite linear;
}
#beer2 > div .bubble:nth-child(2) {
  background-color: rgba(255, 255, 255, 0.74428);
  width: 4.88874996px;
  height: 4.88874996px;
  position: absolute;
  left: 24.72189922%;
  bottom: 0;
  -webkit-animation: bubble 1.29328521s infinite linear;
  -moz-animation: bubble 1.29328521s infinite linear;
  animation: bubble 1.29328521s infinite linear;
  -ms-animation: bubble 1.29328521s infinite linear;
}
#beer2 > div .bubble:nth-child(1) {
  background-color: rgba(255, 255, 255, 0.64577701);
  width: 3.69231682px;
  height: 3.69231682px;
  position: absolute;
  left: 24.20789993%;
  bottom: 0;
  -webkit-animation: bubble 1.26770847s infinite linear;
  -moz-animation: bubble 1.26770847s infinite linear;
  animation: bubble 1.26770847s infinite linear;
  -ms-animation: bubble 1.26770847s infinite linear;
}
@-moz-keyframes bubble {
  to {
    bottom: 100%;
    opacity: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
  }
}
@-webkit-keyframes bubble {
  to {
    bottom: 100%;
    opacity: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
  }
}
@keyframes bubble {
  to {
    bottom: 100%;
    opacity: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
  }
}
